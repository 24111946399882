import React from 'react'

import Layout from '../components/layout'
import NotFound from '../client-pages/404'

const NotFoundPage = () => (
  <Layout>
    <NotFound />
  </Layout>
)

export default NotFoundPage
